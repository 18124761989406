import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useWithdrawalContext from '../../../hooks/useWithdrawalContext';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { Trans, useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import warnImg from '/public/warn.png';

// components
import StepsContainer from '../../deposit/steps/StepsContainer';
import CurrencyDropdown from '../../components/CurrencyDropdown';
import Input from '../../../components/Input';
import Button from '../../../components/CustomButton';

const FirstStep = () => {
  const { address } = useAccount(); // Получаем адрес пользователя
  // const address = '0xfe94879a4ced105a97d44ddf4885d2b3828fbdb5'

  const [availableToWithdrawAPI, setAvailableToWithdrawAPI] = useState<string>('0.00');
  const [dsfLpBalance, setDsfLpBalance] = useState<string>('0.000000000000000000');
  const [loading, setLoading] = useState<boolean>(true); // Флаг загрузки
  const [undistributedProfits, setUndistributedProfits] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModal2, setShowModal2] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    selectedCurrency,
    changeSelectedCurrency,
    amountToWithdraw,
    changeAmountToWithdraw,
    changeCurrentStep,
    changeAmountToWithdrawInPercentage,
  } = useWithdrawalContext();
  // const {
  //   userDeposits,
  //   withdrawableIncome,
  // } = useGlobalContext();
  const { t } = useTranslation('withdraw');

  // Вызов API для получения данных
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!address) return; // Если адреса нет, не вызываем API

        const response = await axios.get(`https://api2.dsf.finance/request/walletinfo?wallet=${address}&apikey=a5d8b4f2-6e3a-49b1-8c5e-3d7b2a4c6f1e`);

        if (response.status === 200) {
          const data = response.data;
          setAvailableToWithdrawAPI(data.available_to_withdraw);
          setDsfLpBalance(data.dsf_lp_balance);
        } else {
          console.error("Ошибка API: статус не 200");
        }
      } catch (error) {
        console.error("Ошибка при получении данных из API:", error);
      } finally {
        setLoading(false); // Выключаем индикатор загрузки после завершения запроса
      }
    };
    fetchData();
    async function fetchWalletData(){
      if(!address) return;
      const response = await axios.get(`https://api2.dsf.finance/request/walletinfo?wallet=${address}&apikey=a5d8b4f2-6e3a-49b1-8c5e-3d7b2a4c6f1e`);
      if(response.status === 200){
        const data = response.data;
        console.log(data)
        let value = parseFloat((Number(data.crv_cost) + Number(data.cvx_cost)).toFixed(2))
        setUndistributedProfits(value);
        if(value > 1){
          // setShowModal(true); //расскомментить тут для показа всплывающего окна
        }
    }
  }
  fetchWalletData()
  }, [address]);
  
  const availableToWithdraw = useMemo(() => {
    return parseFloat(availableToWithdrawAPI) || 0;
  }, [availableToWithdrawAPI]);

  const isWithdrawAmountValid = useMemo(() => {
    if (!amountToWithdraw || !selectedCurrency) return false;
    if (Number(amountToWithdraw) <= 0) return false;

    return Number(amountToWithdraw) <= availableToWithdraw;
  },[amountToWithdraw, availableToWithdraw, selectedCurrency]);

  useEffect(() => {
    changeAmountToWithdrawInPercentage(+amountToWithdraw / availableToWithdraw);
  }, [amountToWithdraw, availableToWithdraw, changeAmountToWithdrawInPercentage]);

  function askNotificationPermission() {
    if (!("Notification" in window)) {
      alert("Ваш браузер не поддерживает уведомления.");
      return;
    }
  
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Уведомления разрешены!");
      } else {
        console.log("Уведомления отклонены.");
      }
    });
  }

  return (
    <StepsContainer title={t('deal')}>
      {showModal ?(
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-30"
              onClick={() => setShowModal(false)}
              >
              <div className={`bg-white ${window.innerWidth < 800 ? 'w-[95vw] h-[90vh]' : 'w-[650px] h-[500px]'} p-5 rounded-[20px] z-50 flex flex-col items-center justify-around text-black text-center`}>
                <img src='/warn.png' alt="" />
                <h2 className='text-blue text-2xl' >
                <Trans t = {t} values={{ unspended: undistributedProfits }}>
                  unspended_text_h2
                  </Trans>
                </h2>
                <p className='text-[20px]' >
                  <Trans t = {t} values={{ unspended: undistributedProfits }}>
                  unspended_text_<span className="font-bold">1</span>
                  </Trans>
                </p>
                <p className='text-[20px]' >
                  <Trans t = {t} values={{ unspended: undistributedProfits }}>
                  unspended_text_2<span className="font-bold">1</span>
                  </Trans>
                </p>
              <div className={`
                flex
                ${window.innerWidth < 800 ? 'flex-col gap-5' : 'flex-row gap-5'}
                justify-center
                z-50
              `}>
              <Button
                variant="outline"
                onClick={() => setShowModal(false)}
                disabled={false}
              >
                {t('btn_continue')}
              </Button>
              <Button
                variant="filled"
                onClick={() => {setShowModal2(true); setShowModal(false)}}
                disabled={false}
              >
                {t('btn_wait_ak')}
              </Button>
              </div>
              </div>
            </div>
      ):null}
      {showModal2 ?(
              <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-30"
              onClick={() => setShowModal2(false)}
              >
              <div className={`bg-white ${window.innerWidth < 800 ? 'w-[95vw] h-[90vh]' : 'w-[650px] h-[500px]'} p-5 rounded-[20px] z-50 flex flex-col items-center justify-around text-black text-center`}>
                <div className="flex flex-row items-center justify-center gap-2">
                <img src='/check.png' alt="" />
                <h2 className='text-blue text-2xl' >
                <Trans t = {t}>
                withdraw_cancelled
                  </Trans>
                </h2>
                </div>
                <p className='text-[20px]' >
                  <Trans t = {t} >
                  withdraw_cancelled_info
                  </Trans>
                </p>
              <div className={`
                flex
                ${window.innerWidth < 800 ? 'flex-col gap-5' : 'flex-col gap-5'}
                justify-center
                z-50
              `}>
              <button
              className='
                border-solid
                border-blue
                border
                py-3
                px-8
                rounded-[20px]
                w-full
                mobile:w-full
                tablet:w-full
                font-semibold
                bg-blue
                text-white
                hover:bg-blue-100
                hover:text-white
                disabled:opacity-20
                disabled:pointer-events-none
              '
              onClick={() => {setShowModal2(false); window.open('https://t.me/dsffinance', '_blank'); }}
              ><div className="flex flex-row items-center justify-center gap-2">
              <img src="/tgicon.png" alt="" />
              {t('go_to_channel')}
              </div></button>

              <button
              className='
                border-solid
                border-blue
                border
                py-4
                px-8
                rounded-[20px]
                w-full
                mobile:w-full
                tablet:w-full
                font-semibold
                bg-transparent
                text-blue
                hover:bg-blue-100
                hover:text-white
                disabled:opacity-20
                disabled:pointer-events-none
              '
              onClick={() => setShowModal2(false)}
              >{t('back_to_app')}</button>
              </div>
              </div>
            </div>
      ):null}
      <div className="mt-6 text-[14px]">
        {t('balance_includes')}
      </div>
      <div className="tablet:flex gap-5 items-end">
        <div className="mt-2 tablet:w-1/2">
          <label className="font-medium text-[0.75rem]">{t('asset')}</label>
          <CurrencyDropdown selectedCurrency={selectedCurrency} changeSelectedCurrency={changeSelectedCurrency} />
        </div>
        <div className="mt-6 tablet:mt-2 tablet:w-1/2">
          <Input
            withRange
            value={amountToWithdraw}
            onChange={changeAmountToWithdraw}
            maxValue={availableToWithdraw}
            label={
              <div className="flex items-center justify-between">
                <span>{t('amount_usd')}</span>
                <div className="flex items-center text-sm">
                  {t('manged_in_DSF')}:
                  <span className="ml-1 text-gray-900">
                      $ {parseFloat(availableToWithdraw.toFixed(2)).toLocaleString('en-US')}
                  </span>
                </div>
              </div>
            }
          />
        </div>
      </div>
      <div className="mt-6">
        <Button
          variant="filled"
          onClick={() => changeCurrentStep(1)}
          disabled={!isWithdrawAmountValid}
        >
          {t('next', { ns: 'common' })}
        </Button>
      </div>
    </StepsContainer>
  )
};

export default FirstStep;

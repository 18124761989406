import {
  useEffect,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  useAccount,
  useContractRead,
  erc20ABI,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import useDepositContext from '../hooks/useDepositContext';
import useGlobalContext from '../hooks/useGlobalContext';
import { ethers } from 'ethers';
import axios from 'axios';
import dsfAbi from '../utils/dsf_abi.json';
import dsfAddresses from '../utils/dsf_addresses.json';
import magicAddresses from '../utils/magic_addresses.json';
import magicNumbers from '../utils/magic_numbers.json';
import { useParams } from 'react-router-dom';


// components
import DepositNavigation from './deposit/DepositNavigation';
import DepositCard from './deposit/DepositCard';
import Disclaimer from './deposit/Disclaimer';
import FAQ from './deposit/FAQContainer';
import { Trans, useTranslation } from 'react-i18next';




const DepositPage = () => {
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal()
  const {
    currentStep,
    changeGasPrice,
    changeOptimizedGasPrice,
    setInterestRate,
  } = useDepositContext();
  const {
    ETHPrice,
    gasPrice,
  } = useGlobalContext();
  const { t } = useTranslation('deposit');
  const provider = useRef(ethers.getDefaultProvider('https://rpc.ankr.com/eth'));
  const contract = useRef(new ethers.Contract(dsfAddresses.DSF, dsfAbi, provider.current));
 
 
  const { customPart } = useParams();
  const [sended, setSended] = useState(false); 
  async function addUserToReferral(address: string, customPart: string) {
    if (sended) return;  
    setSended(true);    

    try {
      const resp = await axios.post('https://alex.dsf.finance/api/addUsertoReferal', {
        // const resp = await axios.post('http://localhost:3000/api/addUsertoReferal', {

        address: address,
        customPart: customPart,
      });

      if (resp.data.success) {
        console.log(resp.data.message);
      }
    } catch (error) {
      console.error("Error sending referral request:", error);
    }
  }
  const savedCustomPart = localStorage.getItem('customPart');
  useEffect(() => {
    if (customPart && address) {
      addUserToReferral(address, customPart);
    } else if (customPart && !address) {
      console.log('Сохранение customPart в localStorage');
      localStorage.setItem('customPart', customPart);
    } else if (address && !customPart) {
      if (savedCustomPart) {
        console.log('customPart из localStorage:', savedCustomPart);
        addUserToReferral(address, savedCustomPart);
      } else {
        console.log('customPart не найден в localStorage');
      }
    }
  }, [customPart, address]);

  async function cleanDuplicates(){
   let res = await axios.get('https://alex.dsf.finance/api/cleanDuplicates');
   console.log(res);
  }

  useEffect(() => {
    if(address) {
      if(customPart || savedCustomPart){
        setTimeout(() => {
          console.log('clean duplicates');
          cleanDuplicates();
        }, 10000);
      }
    }
  }, [address]);

  const getYields = useCallback(async () => {
    const response = await axios.get('https://api2.dsf.finance/yields_llama');

    return response.data.data;
  }, []);

  const getAnnualIncome = useCallback((yields: Array<{ apy: number }>): number => {
    return +((yields.reduce((a, b) => (a + b.apy), 0) / yields.length) * 0.80).toFixed(2);
  }, []);

  const estimateDepositFeeInUsd = useCallback(async () => {
    const gasAmountBig = await contract.current.deposit.estimateGas([0,0,1], {
      from: magicAddresses.MAGIC_FROM,
    });
    const gasAmount = ethers.formatUnits(gasAmountBig, 'gwei');
    const gasFee = +gasAmount * gasPrice;
    const gasFeeInUsd = gasFee * ETHPrice * magicNumbers.MAGIC_MULTIPLIER;

    return gasFeeInUsd;
  }, [gasPrice, ETHPrice]);

  const estimateOptimizedDepositFeeInUsd = useCallback(async () => {
   const gasAmountBig = await contract.current.feesOptimizationDeposit.estimateGas([0,0,1], {
     from: magicAddresses.MAGIC_FROM,
   });
    const gasAmount = ethers.formatUnits(gasAmountBig, 'gwei');
    const gasFee = +gasAmount * gasPrice;
    const gasFeeInUsd = gasFee * ETHPrice * magicNumbers.MAGIC_MULTIPLIER;

    return gasFeeInUsd;
  }, [gasPrice, ETHPrice]);

  useEffect(() => {
    estimateDepositFeeInUsd().then(changeGasPrice);
    estimateOptimizedDepositFeeInUsd().then(changeOptimizedGasPrice);
  }, [
    estimateDepositFeeInUsd, estimateOptimizedDepositFeeInUsd,
    changeOptimizedGasPrice, changeGasPrice,
  ]);

  useEffect(() => {
    getYields()
      .then((yields) => {
        setInterestRate(+(yields.at(-1).apy * 0.80).toFixed(2));
      })
      .catch((error) => {
        console.error('Something went wrong while getting yields: ', error);
      });
  }, [getYields, getAnnualIncome]);

  function renderConnectWallet() {
    if(!address && window.innerWidth >800) {
      return(
        <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 flex items-center justify-center">
        <div className="bg-white w-[670px] h-[400px] rounded-3xl shadow-lg flex flex-col items-center justify-center px-[110px]">
        <div className="text-[#3956FE] text-[22px] font-bold text-center">
        {t('please_connect_wallet')}
        </div>
        <button className='flex items-center gap-2 bg-[#3956FE] text-white px-4 py-2 rounded-xl mt-[50px] px-[30px] active:opacity-75' onClick={open}>
          <img src='/metamask.png' alt="wallet" />
          {t('connect_wallet')}
          </button>
          <div className="text-[#979797] text-[16px] font-bold text-center mt-[50px]">
        {t('connect_wallet_description')}
        </div>
        </div>
      </div>
      )
    } else if(!address && window.innerWidth <= 800) {
      return(
        <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 flex items-center justify-center">
        <div className="bg-white w-[95%] h-[80vh] rounded-3xl shadow-lg flex flex-col items-center justify-center px-[50px]">
        <div className="text-[#3956FE] text-[22px] font-bold text-center">
        {t('please_connect_wallet')}
        </div>
        <button className='flex items-center gap-2 bg-[#3956FE] text-white px-4 py-2 rounded-xl mt-[50px] px-[30px] active:opacity-75' onClick={open}>
          <img src='/metamask.png' alt="wallet" />
          {t('connect_wallet')}
          </button>
          <div className="text-[#979797] text-[16px] font-bold text-center mt-[50px]">
        {t('connect_wallet_description')}
        </div>
        </div>
      </div>
      )
    }
  }


  return (
    <>
    {/* {renderConnectWallet()} */}
      <DepositNavigation />
      <DepositCard />
      {
        currentStep > 1
          && (
            <Disclaimer />
          )
      }
      <FAQ />
    </>
  );
};

export default DepositPage;

import {
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  usePrepareContractWrite,
  useContractWrite,
  useBalance,
  useAccount,
  useContractRead,
} from 'wagmi';
import { ethers } from 'ethers';
import useDepositContext from '../../../hooks/useDepositContext';
import useReApprove from '../../../hooks/useReApprove';
import useApprove from '../../../hooks/useApprove';
import tokensAddresses from '../../../utils/tokens_addresses.json';
import usdtAbi from '../../../utils/usdt_abi.json';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { ReactComponent as ETHIcon } from '../../../assets/images/currency/ETH.svg';
import dsfAbi from '../../../utils/dsf_abi.json';
import crvLpAbi from '../../../utils/crv_lp_contract_abi.json';
import dsfAddresses from '../../../utils/dsf_addresses.json';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'flowbite-react';
import { ReactComponent as QuestionMark } from '../../../assets/images/common/question.svg';


// components
import StepsContainer from './StepsContainer';
import CurrencyIcon from '../../../components/CurrencyIcon';
import Button from '../../../components/CustomButton';
import SuccessDeposit from '../../../modals/SuccessDeposit';

const FourthStep = () => {
  const {
    depositAmount,
    selectedCurrency,
    optimized,
    optimizedGasPrice,
    gasPrice,
    resetContext,
    interestRate,
  } = useDepositContext();
  
  const [successModalOpened, setSuccessModalOpened] = useState(false);
  const [selectedCurrencyPrice, setSelectedCurrencyPrice] = useState(0);
  const [resetComplete, setResetComplete] = useState(false);
  const { ETHPrice} = useGlobalContext();
  const navigate = useNavigate();
  const { t } = useTranslation('deposit');
  const { address } = useAccount();
  const { data: ethBalance } = useBalance({ address });

  const decimals = selectedCurrency === 'DAI' ? 18 : 6;
  const depositAmountInUnits = ethers.parseUnits(depositAmount.toString(), decimals);

  const { data: allowanceData, refetch: refetchAllowance } = useContractRead({
    address: tokensAddresses[selectedCurrency || 'USDT'] as `0x${string}`,
    abi: usdtAbi,
    functionName: 'allowance',
    args: [address as `0x${string}`, dsfAddresses.DSF as `0x${string}`],
    watch: true,
  });

  const allowance = allowanceData || BigInt(0);

  const { approve: resetApprove, isLoading: isResetLoading, isSuccess: isResetSuccess } = useReApprove({
    tokenAddress: tokensAddresses[selectedCurrency || 'USDT'] as `0x${string}`,
    amount: depositAmountInUnits,
    isUSDT: selectedCurrency === 'USDT',
  });

  const { approve: approveWrite, isLoading: isApproveLoading } = useApprove(
    tokensAddresses[selectedCurrency || 'USDT'] as `0x${string}`
  );

  const isApproved = useMemo(() => allowance >= depositAmountInUnits, [allowance, depositAmountInUnits]);

  useEffect(() => {
    if (isResetSuccess) {
      console.log('Reset approval completed. Refetching allowance...');
      setResetComplete(true);
      refetchAllowance();
    }
  }, [isResetSuccess, refetchAllowance]);

  const renderApproveButton = useMemo(() => {
    if (selectedCurrency === 'USDT') {
      if (allowance > 0 && allowance < depositAmountInUnits && !resetComplete) {
        return (
          <Button
            variant="filled"
            onClick={resetApprove}
            loading={isResetLoading}>
            {t('reset_approval')}
          </Button>
        );
      }
    }

    if (!isApproved) {
      return (
        <Button
          variant="filled"
          onClick={approveWrite}
          loading={isApproveLoading}>
          {t('approve')}
        </Button>
      );
    }

    return null;
  }, [selectedCurrency, allowance, depositAmountInUnits, isResetLoading, resetApprove, approveWrite, isApproved, resetComplete]);
  
  const depositAmounts = useMemo(() => {
    const amounts = [0, 0, 0];

    switch (selectedCurrency) {
      case 'DAI':
        amounts[0] = +ethers.parseUnits(depositAmount, 18).toString();
        break;
      case 'USDC':
        amounts[1] = +ethers.parseUnits(depositAmount, 6).toString();
        break;
      case 'USDT':
        amounts[2] = +ethers.parseUnits(depositAmount, 6).toString();
        break;
      default:
        break;
    }

    return amounts;
  }, [depositAmount, selectedCurrency]);

  const { config, isSuccess: prepareSuccess, error } = usePrepareContractWrite({
    address: dsfAddresses.DSF as `0x${string}`,
    abi: dsfAbi,
    functionName: optimized ? 'feesOptimizationDeposit' : 'deposit',
    args: [depositAmounts],
  });
  
  const {
    data: depositResult,
    isLoading: isDepositLoading,
    isSuccess: isDepositSuccess,
    write: deposit,
  } = useContractWrite(config);

  const handleDeposit = useCallback(() => {
    if (!isApproved) return;
    if (deposit) deposit();
  }, [isApproved, deposit]);

  useEffect(() => {
    if (isDepositSuccess && !isDepositLoading) {
      setSuccessModalOpened(true);
    }
  }, [isDepositSuccess, isDepositLoading]);
  
  const { data: lpShares, error: lpSharesError }: { data: bigint | undefined; error: any } = useContractRead({
    address: dsfAddresses.DSF as `0x${string}`,
    abi: dsfAbi,
    functionName: 'calcSharesAmount',
    args: [depositAmounts.map((amount) => BigInt(Math.floor(amount * (1 - 0.00098)))), true],
    watch: false,
  });
  
  const { data: crvLpPrice, error: crvLpPrceError }: { data: bigint | undefined; error: any } = useContractRead({
    address: dsfAddresses.crvLpContract as `0x${string}`,
    abi: crvLpAbi,
    functionName: 'get_virtual_price',
    args: [],
    watch: false,
  });
  if (error) {
    console.log('error: ', error);
  }
  if (lpSharesError) {
    console.log('lpSharesError: ', lpSharesError);
  }
  if (crvLpPrceError) {
    console.log('crvLpPrceError: ', crvLpPrceError);
  }

  const mimAmount = useMemo(() => {
    if (!lpShares || !crvLpPrice) return 0;

    return +ethers.formatUnits(lpShares, 18) * +ethers.formatUnits(crvLpPrice, 18);
  }, [lpShares, crvLpPrice]);

  const depositResultWithSlippage = useMemo(() => {
    return Number((mimAmount * (selectedCurrencyPrice - selectedCurrencyPrice * 0.004)).toFixed(2));
  }, [mimAmount, selectedCurrencyPrice]);

  const estimatedFeeETH = useMemo(() => {
    if (!depositAmount || !selectedCurrency || !ETHPrice) return 0;

    if (optimized) {
      return +(optimizedGasPrice / ETHPrice).toFixed(4);
    }

    return +(gasPrice / ETHPrice).toFixed(4);
  } ,[
    depositAmount, optimizedGasPrice,
    gasPrice, ETHPrice, selectedCurrency,
    optimized
  ]);

  const depositHash = useMemo(() => {
    if (!depositResult) return '';

    return depositResult.hash;
  }, [depositResult]);

  const disabledDepositButton = useMemo(() => {
    if (!ethBalance) return true;
    return isDepositLoading || !prepareSuccess || +ethBalance.formatted < estimatedFeeETH;
  }, [isDepositLoading, prepareSuccess, ethBalance, estimatedFeeETH]);

  const closeSuccessModal = useCallback(() => {
    setSuccessModalOpened(false);
    navigate('/transactions');
    resetContext();
  }, [setSuccessModalOpened, resetContext, navigate]);

  const depositButtonClickHandler = useCallback(() => {
    deposit?.();
  },[deposit]);

  // useEffect(() => {
  //   if (isSuccess && !isLoading) {
  //     setSuccessModalOpened(true);
  //   }
  // }, [isSuccess, isLoading]);

  useEffect(() => {
    if (!selectedCurrency) return;
    axios.get('https://api.curve.fi/api/getPools/ethereum/factory')
      .then((bigResponse) => {
        return bigResponse.data.data.poolData;
      })
      .then((pools) => {
        return pools.find((pool: any) => pool.id === 'factory-v2-40')
      })
      .then((pool) => {
        if (pool) {
          return pool.underlyingCoins
        }
      })
      .then((coins) => {
        if (coins) {
          const coinData = coins.find((coin: any) => coin.symbol === selectedCurrency);
          if (coinData) {
            setSelectedCurrencyPrice(coinData.usdPrice);
          }
        }
      })
      .catch((error) => {
        console.log('Something went wrong while getting selected currency price: ', error);
      });
  }, [selectedCurrency]);

  if (!selectedCurrency) {
    return null;
  }

  return (
    <StepsContainer title={t('review')}>
      <div className="tablet:flex items-center gap-5">
        <label className="block mt-6 tablet:w-1/3">
          <span className="text-sm">
            {t('your_deposit')}
          </span>
          <div className="flex items-center gap-[10px] bg-gray rounded-xl px-4 py-3 mt-1">
            <CurrencyIcon currency={selectedCurrency}/>
            <span>{depositAmount}</span>
            <span>{selectedCurrency}</span>
          </div>
        </label>
        <label className="block mt-5 tablet:w-1/3">
          <span className="text-sm">
            {t('estimate_rate')}
          </span>
          <div className="flex items-center gap-[10px] bg-gray rounded-xl px-4 py-3 mt-1">
            <span>{interestRate}%</span>
          </div>
        </label>
        <label className="block mt-5 tablet:w-1/3">
          <span className="text-sm">
            {t('blockchain_fee')}
          </span>
          <div className="flex items-center justify-between bg-gray rounded-xl px-4 py-3 mt-1">
            <span>${(optimized ? optimizedGasPrice : gasPrice).toFixed(2)}</span>
            <div className="flex items-center gap-3">
              <div className="flex justify-center items-center bg-gray rounded-full w-6 h-6">
                <ETHIcon/>
              </div>
              {estimatedFeeETH} ETH
            </div>
          </div>
        </label>
        <label className="block mt-6 tablet:w-1/3">
        <div className="flex flex-row">
        <span className="text-sm">
            {t('result_slippage')}
          </span>
          <div className="w-[30px] mt-[-2px]">
          <Tooltip
                  content={t('fourth_step_toltip')}
                  style="light"
                  arrow={false}
                  className="w-[300px]"
                >
                  <QuestionMark className="ml-2 h-5 w-5"/>
                </Tooltip>
          </div>
        </div>
          <div className="flex items-center gap-[10px] bg-gray rounded-xl px-4 py-3 mt-1">
            <CurrencyIcon currency={selectedCurrency}/>
            <span>{depositResultWithSlippage}</span>
            <span>{selectedCurrency}</span>
          </div>
        </label>
      </div>
      <div
        className={`
          ${!ethBalance || +ethBalance.formatted < estimatedFeeETH ? 'block' : 'hidden'} font-light text-red-500
        `}
      >
        {
          t(
            'not_enough_ETH',
            { amount: +((estimatedFeeETH - +(ethBalance?.formatted || 0)) * 1.15).toFixed(7) }
          )
        }
      </div>
      <div className="mt-6">
        {renderApproveButton}
        {isApproved && (
          <Button
            variant="filled"
            onClick={handleDeposit}
            disabled={isDepositLoading}>
            {t('place_deposit')}
          </Button>
        )}
      </div>
      {!isApproved && (
        <div hidden={isApproved} className="font-medium mt-6 text-[14px] leading-[20px]">
          {selectedCurrency === 'USDC' || selectedCurrency === 'DAI'
            ? t('you_will_be_redirected')
            : allowance > 0 && allowance < depositAmountInUnits && !resetComplete
              ? t('you_will_be_redirected_reapprove')
              : t('you_will_be_redirected')}
        </div>
      )}
      <SuccessDeposit
        opened={successModalOpened}
        onClose={closeSuccessModal}
        etherscanLink={`https://etherscan.io/tx/${depositHash}`}
      />
    </StepsContainer>
  )
};

export default FourthStep;

import {
  useEffect,
  useCallback,
} from 'react';
import useGlobalContext from '../../hooks/useGlobalContext';
import useTokensBalance from '../../hooks/useTokensBalance';
import {
  useAccount,
  useFeeData,
  useContractRead,
  // erc20ABI,
} from 'wagmi';
import axios from 'axios';
import { ethers } from 'ethers';
import dsfAddresses from '../../utils/dsf_addresses.json';
import dsfABI from '../../utils/dsf_abi.json';
import dsfHelperABI from '../../utils/dsf_helper_abi.json';
import strategyABI from '../../utils/strategy_abi.json';
// import tokensAddresses from '../../utils/tokens_addresses.json';
// import crvRewardsAbi from '../../utils/crv_rewards_abi.json';
// import crvLpContractAbi from '../../utils/crv_lp_contract_abi.json';

interface DataContainerProps {
  children: React.ReactNode;
}

const DataContainer = (props: DataContainerProps) => {
  const { children } = props;
  const {
    changeWallet,
    changeBalance,
    balance,
    changeGasPrice,
    setETHPrice,
    changeManagedInDSF,
    changeDSFLPPrice,
    changeDSFLPTotalSupply,
    changeUserDeposits,
    changeInterestRate,
    changeCurrentInterestRate,
    // managedInDSF,
    userDeposits,
    // dsfLPPrice,
    dsfLpBalance,
    changeWithdrawableIncome,
    changeDsfLpBalance,
  } = useGlobalContext();
  const {
    address,
  } = useAccount();
  const {
    USDT: usdtBalance ,
    USDC: usdcBalance,
    DAI: daiBalance
  } = useTokensBalance();
  const { data: feeData } = useFeeData({
    watch: true,
  });
  const { data: dsfLPBalance, isSuccess: isBalanceSuccess } = useContractRead({
    address: dsfAddresses.DSF as `0x${string}`,
    abi: dsfABI,
    functionName: 'balanceOf',
    args: [address],
    //args: ['0x9Af20CCf97b53BC40B1741D44C5AD34f28eE4CE7'], // 78k
    watch: true,
  });
  const { data: lpPrice, isSuccess: isPriceSuccess } = useContractRead({
    address: dsfAddresses.DSF as `0x${string}`,
    abi: dsfABI,
    functionName: 'lpPrice',
    watch: true,
  });
  const { data: dsfLPTotalSupply, isSuccess: isTotalSupplySuccess } = useContractRead({
    address: dsfAddresses.DSF as `0x${string}`,
    abi: dsfABI,
    functionName: 'totalSupply',
    watch: true,
  });
  const { data: percentAvailableToWithdraw } = useContractRead({
    address: dsfAddresses.DSFHelper as `0x${string}`,
    abi: dsfHelperABI,
    functionName: 'calcLpRatioSafe',
    args: [dsfLpBalance, dsfLPTotalSupply],
  });
  const { data: availableToWithdraw }: { data?: bigint; } = useContractRead({
    address: dsfAddresses.DSFStrategy as `0x${string}`,
    abi: strategyABI,
    functionName: 'calcWithdrawOneCoin',
    // 2 stands for USDT
    args: [percentAvailableToWithdraw, 2],
  });
  // const { data: totalDeposited }: { data: bigint | undefined } = useContractRead({
  //   address: dsfAddresses.DSF as `0x${string}`,
  //   abi: dsfABI,
  //   functionName: 'totalDeposited',
  // });
  // const { data: totalHoldings }: { data: bigint | undefined } = useContractRead({
  //   address: dsfAddresses.DSF as `0x${string}`,
  //   abi: dsfABI,
  //   functionName: 'totalHoldings',
  // });
  // const { data: DSFUSDTBalance } = useContractRead({
  //   address: tokensAddresses.USDT as `0x${string}`,
  //   abi: erc20ABI,
  //   functionName: 'balanceOf',
  //   args: [dsfAddresses.DSFStrategy as `0x${string}`],
  // });
  // const { data: DSFUSDCBalance } = useContractRead({
  //   address: tokensAddresses.USDC as `0x${string}`,
  //   abi: erc20ABI,
  //   functionName: 'balanceOf',
  //   args: [dsfAddresses.DSFStrategy as `0x${string}`],
  // });
  // const { data: DSFDAIBalance } = useContractRead({
  //   address: tokensAddresses.DAI as `0x${string}`,
  //   abi: erc20ABI,
  //   functionName: 'balanceOf',
  //   args: [dsfAddresses.DSFStrategy as `0x${string}`],
  // });
  // const { data: DSFCRVBalance }: { data: bigint | undefined } = useContractRead({
  //   address: dsfAddresses.crvRewards as `0x${string}`,
  //   abi: crvRewardsAbi,
  //   functionName: 'balanceOf',
  //   args: [dsfAddresses.DSFStrategy],
  // });
  // const { data: CRVVirtualPrice }: { data: bigint | undefined } = useContractRead({
  //   address: dsfAddresses.crvLpContract as `0x${string}`,
  //   abi: crvLpContractAbi,
  //   functionName: 'get_virtual_price',
  // });

  const getEthPrice = useCallback(async () => {
    const ethPriceInUsdResponse = await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD');

    return ethPriceInUsdResponse.data.USD;
  }, []);

  const fixedAddress = "0x9Af20CCf97b53BC40B1741D44C5AD34f28eE4CE7"; // Определим fixedAddress здесь

  const getUserDeposits = useCallback(async () => {
  
    try {
      const response = await axios.get(`https://api.dsf.finance/deposit/${address}`);
      //const response = await axios.get(`https://api.dsf.finance/deposit/0x9Af20CCf97b53BC40B1741D44C5AD34f28eE4CE7`); //78k
      return response.data;
    } catch (error) {
      console.error('Something went wrong');
    }
    return 0;
  }, [address]);

  const getYields = useCallback(async () => {
    const response = await axios.get('https://api2.dsf.finance/yields_llama');

    return response.data.data;
  }, []);

  const getAnnualIncome = useCallback((yields: Array<{ apy: number }>): number => {
    return +((yields.reduce((a, b) => (a + b.apy), 0) / yields.length) * 0.80).toFixed(2);
  }, []);

  useEffect(() => {
    const available = ethers.formatUnits(availableToWithdraw || BigInt(0), 6);
    const userDepositsSum = Object.values(userDeposits).reduce((a, b) => a + b, 0);
    const income = Number(available) - userDepositsSum;

    changeWithdrawableIncome(userDeposits.beforeCompound === 0 ? 0 : income);
  }, [availableToWithdraw, userDeposits, changeWithdrawableIncome]);

  useEffect(() => {
    changeWallet(address || '');
  }, [address, changeWallet]);

  useEffect(() => {
    if (usdtBalance !== balance.USDT) {
      changeBalance('USDT', usdtBalance);
    }
  }, [usdtBalance, balance, changeBalance]);

  useEffect(() => {
    if (usdcBalance !== balance.USDC) {
      changeBalance('USDC', usdcBalance);
    }
  }, [usdcBalance, balance, changeBalance]);

  useEffect(() => {
    if (daiBalance !== balance.DAI) {
      changeBalance('DAI', daiBalance);
    }
  }, [daiBalance, balance, changeBalance]);

  useEffect(() => {
    if (
      feeData && feeData.formatted &&
      feeData.formatted.gasPrice
    ) {
      changeGasPrice(+feeData.formatted.gasPrice);
    }
  }, [feeData, changeGasPrice]);

  useEffect(() => {
    getEthPrice().then(setETHPrice);
  }, [getEthPrice, setETHPrice]);

  useEffect(() => {
    if (lpPrice && isPriceSuccess) {
      changeDSFLPPrice(+ethers.formatUnits(lpPrice as bigint, 18));
    }
  }, [lpPrice, isPriceSuccess, changeDSFLPPrice]);

  useEffect(() => {
    if (dsfLPBalance && isBalanceSuccess) {
      changeDsfLpBalance(dsfLPBalance as bigint);
      changeManagedInDSF(
        +ethers.formatUnits(dsfLPBalance as bigint, 18)
      );
    }
  }, [dsfLPBalance, isBalanceSuccess, changeManagedInDSF]);

  useEffect(() => {
    if (dsfLPTotalSupply && isTotalSupplySuccess) {
      changeDSFLPTotalSupply(
        +ethers.formatUnits(dsfLPTotalSupply as bigint, 18)
      );
    }
  }, [dsfLPTotalSupply, isTotalSupplySuccess, changeDSFLPTotalSupply]);

  useEffect(() => {
    getUserDeposits().then(changeUserDeposits);
  }, [getUserDeposits, changeUserDeposits]);

  useEffect(() => {
    getYields()
      .then((yields) => {
        changeInterestRate(getAnnualIncome(yields));
        changeCurrentInterestRate(+(yields.at(-1).apy * 0.80).toFixed(2));
      })
      .catch((error) => {
        console.error('Something went wrong while getting yields: ', error);
      });
  }, [getYields, getAnnualIncome, changeInterestRate]);

  return (
    <>
      {children}
    </>
  )
};

export default DataContainer;

// src/hooks/useReApprove.tsx

import { useState, useEffect, useCallback } from 'react';
import { usePrepareContractWrite, useContractWrite } from 'wagmi';
import usdtAbi from '../utils/usdt_abi.json';
import dsfAddresses from '../utils/dsf_addresses.json';
import { ethers } from 'ethers';

interface UseReApproveProps {
  tokenAddress: `0x${string}`;
  amount: bigint;
  isUSDT: boolean;
}

const useReApprove = ({ tokenAddress, amount, isUSDT }: UseReApproveProps) => {
  const [resetStep, setResetStep] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [currentTx, setCurrentTx] = useState<'reset' | 'approve' | null>(null);

  // Логирование начальных значений для отладки
  useEffect(() => {
    console.log('useReApprove initialized with:');
    console.log('tokenAddress:', tokenAddress);
    console.log('amount:', amount.toString());
    console.log('isUSDT:', isUSDT);
  }, [tokenAddress, amount, isUSDT]);

  // Шаг 1: Сбросить allowance до нуля, если это необходимо
  const { config: resetConfig, error: resetError } = usePrepareContractWrite({
    chainId: 1, // Убедитесь, что это правильный chainId
    address: tokenAddress,
    abi: usdtAbi,
    functionName: 'approve',
    args: [dsfAddresses.DSF as `0x${string}`, BigInt(0)],
    enabled: isUSDT && !resetStep && amount > BigInt(0),
  });

  useEffect(() => {
    if (resetError) {
      console.error('Ошибка при подготовке транзакции сброса allowance:', resetError);
      setError(resetError);
    }
    console.log('resetConfig:', resetConfig);
  }, [resetError, resetConfig]);

  const { write: resetApprove, isSuccess: isResetSuccess, isLoading: isResetLoading } = useContractWrite(resetConfig);

  // Шаг 2: Установить новое значение allowance после сброса
  const { config: approveConfig, error: approveError } = usePrepareContractWrite({
    chainId: 1, // Убедитесь, что это правильный chainId
    address: tokenAddress,
    abi: usdtAbi,
    functionName: 'approve',
    args: [dsfAddresses.DSF as `0x${string}`, amount],
    enabled: isUSDT ? resetStep && amount > BigInt(0) : amount > BigInt(0),
  });

  useEffect(() => {
    if (approveError) {
      console.error('Ошибка при подготовке транзакции approve:', approveError);
      setError(approveError);
    }
    console.log('approveConfig:', approveConfig);
    console.log('approveConfig enabled:', isUSDT ? resetStep && amount > BigInt(0) : amount > BigInt(0));
  }, [approveError, approveConfig, isUSDT, resetStep, amount]);

  const { write: approveWrite, isSuccess: isApproveSuccess, isLoading: isApproveLoading } = useContractWrite(approveConfig);

  // Обработка успешного сброса allowance
  useEffect(() => {
    if (isResetSuccess) {
      console.log('Allowance успешно сброшен до нуля');
      setResetStep(true);
      setCurrentTx('approve');
    }
  }, [isResetSuccess]);

  // Обработка успешного одобрения
  useEffect(() => {
    if (isApproveSuccess) {
      console.log('Allowance успешно установлен');
      setCurrentTx(null);
      // Сбросить resetStep для будущих одобрений
      setResetStep(false);
    }
  }, [isApproveSuccess]);

  // Функция для запуска процесса одобрения
  const approve = useCallback(() => {
    if (isUSDT) {
      if (!resetApprove) {
        console.error('Функция resetApprove не определена');
        return;
      }
      console.log('Выполняется сброс allowance до нуля...');
      setCurrentTx('reset');
      resetApprove();
    } else {
      if (!approveWrite) {
        console.error('Функция approveWrite не определена');
        return;
      }
      console.log('Выполняется approve...');
      setCurrentTx('approve');
      approveWrite();
    }
  }, [isUSDT, resetApprove, approveWrite]);

  // Дополнительное логирование для отладки
  useEffect(() => {
    console.log('resetStep:', resetStep);
    console.log('currentTx:', currentTx);
    console.log('approveWrite:', approveWrite);
    console.log('resetApprove:', resetApprove);
  }, [resetStep, currentTx, approveWrite, resetApprove]);

  return {
    approve,
    isLoading: isResetLoading || isApproveLoading,
    isSuccess: isApproveSuccess,
    error,
    currentTx,
  };
};

export default useReApprove;
